// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormLabel,
    Select,
    SimpleGrid,
    Icon,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useTheme,
    Input,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody, AlertDialogFooter,
} from '@chakra-ui/react';

// Custom components
import Card from '../../../../components/card/Card';
import InputField from '../../../../components/fields/InputField';
import React, {useEffect, useRef, useState} from 'react';

import useGetCategories from "../../../../api/productLibrary/getCategories";
import CustomCirclePicker from "../productList/components/CustomCirclePicker";

import {useForm, Controller} from "react-hook-form";
import useGetModifierGroups from "../../../../api/productLibrary/getModifierGroups";
import useGetVariantGroups from "../../../../api/productLibrary/getVariantGroups";
import useGetOrderModes from "../../../../api/productLibrary/getOrderModes";
import useGetTaxRates from "../../../../api/productLibrary/getTaxRates";

import {Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import {useCreateProduct} from "../../../../api/productLibrary/createProduct";
import {useNavigate} from "react-router-dom";
import CurrencyInputFieldWithProps from "../../../../components/fields/CurrencyInputFieldWithProps";
import AddCategoryModal from "../categoryList/components/AddCategoryModal";
import VariantGroupModal from "../portionsList/components/VariantGroupModal";
import {AddIcon} from "@chakra-ui/icons";
import useGetProducts from "../../../../api/productLibrary/getProducts";


export default function NewProduct() {
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const [{data: variantGroups, refetch: refetchVariants}] = useGetVariantGroups();
    const [{data: modifierGroups}] = useGetModifierGroups();
    const [{data: orderModes}] = useGetOrderModes();
    const [{data: taxRates}] = useGetTaxRates();
    const [{data: existingProducts}] = useGetProducts();

    const {onCreateProduct, isLoading: createLoading, error} = useCreateProduct();

    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen : isPortionOpen, onOpen : onPortionOpen, onClose : onPortionClose} = useDisclosure();
    const {isOpen : isCategoryOpen, onOpen : onCategoryOpen, onClose : onCategoryClose} = useDisclosure();

    const cancelRef = useRef();

    const [dataLoaded, setDataLoaded] = useState(false);

    const {handleSubmit, control, formState: {errors, isValid}, getValues, watch, setValue, register} = useForm({
        mode: 'onChange'
    });

    const watchVariantGroup = watch("variantGroup");
    const watchModifierGroup = watch("modifierGroup")

    const navigate = useNavigate();

    const [customerName, setCustomerName] = useState("");
    const [kitchenName, setKitchenName] = useState("");

    const [selectedPortion, setSelectedPortion] = useState("");

    const [created, setCreated] = useState(false)

    const [defaultTaxRate, setDefaultTaxRate] = useState('no-tax');

    const name = watch("name");

    useEffect(() => {
        setCustomerName(name);
        setKitchenName(name);
    }, [name]);

    const onSubmitHandler = async (data: any) => {

        await onCreateProduct(data);
        setCreated(true);

        onOpen();
    }

    const goToAddNewProduct = () => {
        onClose();
        window.location.reload();
    }

    const goToAllProducts = () => {
        onClose();
        navigate("/admin/library/products");
    }

    type PricingData = {
        [key: string]: { price: string; taxRate: string }
    }

    const [variantData, setVariantData] = useState([]);
    const [pricingData, setPricingData] = useState<PricingData>({});

    useEffect(() => {
        if (variantGroups?.length && orderModes?.length) {
            const newVariantData: React.SetStateAction<any[]> = [];
            const newPricingData = {};

            if (watchVariantGroup) {

                let group = variantGroups.find((vG: { _id: string; }) => {
                    return vG._id === watchVariantGroup
                })


                    group.variants.forEach((variant: any) => {
                        newVariantData.push(variant);
                        orderModes.forEach((mode: { name: any; _id: string, id: any }) => {
                            const key = `${variant.name}-${mode.name}`;
                            // @ts-ignore
                            newPricingData[key] = {price: "", taxRate: "", _id: mode._id}; // Add modeId here
                        });
                    });


            } else {
                orderModes.forEach((mode: { name: any; _id: string; id: any; }) => {
                    const key = `${mode.id}`;
                    // @ts-ignore
                    newPricingData[key] = {price: "", taxRate: "", _id: mode._id}; // Add modeId here
                });
            }

            setVariantData(newVariantData);
            setPricingData(newPricingData);
            setDataLoaded(true);
        }
    }, [variantGroups, orderModes, watchVariantGroup]);


    React.useEffect(() => {
        if (watchVariantGroup) setValue("modifierGroup", "");
        if (watchModifierGroup) setValue("variantGroup", "");
    }, [watchVariantGroup, watchModifierGroup]);


    useEffect(() => {
        if (taxRates && taxRates?.length > 0) {
            const firstTaxRateId = taxRates[0]._id;
            setDefaultTaxRate(firstTaxRateId);
        }
    }, [taxRates]);


    const [activeBullets, setActiveBullets] = useState({
        product: true,
        media: false,
        pricing: false,
    });

    const productTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const pricingTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const theme = useTheme();
    //eslint-disable-next-line
    const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
    //eslint-disable-next-line
    const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
    const [nameError, setNameError] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectNewCategory, setSelectNewCategory] = useState(false);

    const [{data: categories, isLoading, isError, refetch}] = useGetCategories();

    const handleCategoryClose = async (newCategory: any) => {
        onClose();
      
            setSelectNewCategory(true); // Set flag
            await refetch();

    };

    useEffect(() => {
        if (selectNewCategory && categories && categories.length > 0) {
            // Get the last category in the updated list
            const newCategory = categories[categories.length - 1];
            setSelectedCategory(newCategory._id);
            setValue('category', newCategory._id);
            setSelectNewCategory(false);
        }
    }, [categories]);

    const handleNewCategory = async () => {
        await refetch();
        if (categories && categories.length > 0) {
            const newCategory = categories[categories.length - 1];
            setSelectedCategory(newCategory._id);
            setValue('category', newCategory._id);
        }
    };


    const checkDuplicateName = (value: string) => {
        const lowerCaseValue = value.toLowerCase();
        const isDuplicate = existingProducts.some((product: { name: string; }) => product.name.toLowerCase() === lowerCaseValue);
        if (isDuplicate) {
            setNameError("You already have a product with this name. We don't recommend duplicate names.");
        } else {
            setNameError('');
        }
    };

    // @ts-ignore
    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{sm: '125px', lg: '75px'}}
            position="relative"
        >
            <Box
                h="25vh"
                bgGradient="linear(to-b, brand.400, brand.600)"
                position="absolute"
                w="100%"
                borderRadius="20px"
            />

            <Tabs
                variant="unstyled"
                mt={{base: '60px', md: '80px'}}
                zIndex="0"
                display="flex"
                flexDirection="column"
            >
                <TabList
                    display="flex"
                    alignItems="center"
                    alignSelf="center"
                    justifySelf="center"
                >


                    <Tab
                        _focus={{border: '0px', boxShadow: 'unset'}}
                        ref={productTab}
                        w={{sm: '120px', md: '250px', lg: '300px'}}
                        onClick={() =>
                            setActiveBullets({
                                product: true,
                                media: false,
                                pricing: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                            _before={{
                                content: "''",
                                width: {sm: '120px', md: '250px', lg: '300px'},
                                height: '3px',
                                bg: activeBullets.media ? 'white' : 'brand.400',
                                left: {sm: '12px', md: '40px'},
                                top: {
                                    sm: activeBullets.product ? '6px' : '4px',
                                    md: null,
                                },
                                position: 'absolute',
                                bottom: activeBullets.product ? '40px' : '38px',

                                transition: 'all .3s ease',
                            }}
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={activeBullets.product ? 'white' : 'brand.400'}
                                bgGradient="linear(to-b, brand.400, brand.600)"
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />
                            <Text
                                color={activeBullets.product ? 'white' : 'gray.300'}
                                fontWeight={activeBullets.product ? 'bold' : 'normal'}
                                display={{sm: 'none', md: 'block'}}
                            >
                                Product Info
                            </Text>
                        </Flex>
                    </Tab>
                    <Tab
                        _focus={{border: '0px', boxShadow: 'unset'}}
                        ref={mediaTab}
                        w={{sm: '120px', md: '250px', lg: '300px'}}
                        onClick={() =>
                            setActiveBullets({
                                product: true,
                                media: true,
                                pricing: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                            _before={{
                                content: "''",
                                width: {sm: '120px', md: '250px', lg: '300px'},
                                height: '3px',
                                bg: activeBullets.pricing ? 'white' : 'brand.400',
                                left: {sm: '12px', md: '28px'},
                                top: '6px',
                                position: 'absolute',
                                bottom: activeBullets.media ? '40px' : '38px',

                                transition: 'all .3s ease',
                            }}
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={activeBullets.media ? 'white' : 'brand.400'}
                                bgGradient="linear(to-b, brand.400, brand.600)"
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />
                            <Text
                                color={activeBullets.media ? 'white' : 'gray.300'}
                                fontWeight={activeBullets.media ? 'bold' : 'normal'}
                                display={{sm: 'none', md: 'block'}}
                            >
                                Advanced Options
                            </Text>
                        </Flex>
                    </Tab>
                    <Tab
                        _focus={{border: '0px', boxShadow: 'unset'}}
                        ref={pricingTab}
                        w={{sm: '120px', md: '250px', lg: '300px'}}
                        onClick={() =>
                            setActiveBullets({
                                product: true,
                                media: true,
                                pricing: true,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={activeBullets.pricing ? 'white' : 'brand.400'}
                                bgGradient="linear(to-b, brand.400, brand.600)"
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />
                            <Text
                                color={activeBullets.pricing ? 'white' : 'gray.300'}
                                fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
                                display={{sm: 'none', md: 'block'}}
                            >
                                Pricing
                            </Text>
                        </Flex>
                    </Tab>
                </TabList>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <TabPanels mt="24px" maxW={{md: '90%', lg: '100%'}} mx="auto">
                        <TabPanel
                            w={{sm: '330px', md: '700px', lg: '850px'}}
                            p="0px"
                            mx="auto"
                        >
                            <Card p="30px">
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Basic Product Info
                                </Text>
                                <Flex direction="column" w="100%">
                                    <SimpleGrid columns={{base: 1, md: 2}} gap="20px">
                                        <Stack direction="column" gap="20px">
                                            <InputField {...register("name",{ required: 'Product name is required', onBlur: (e) => checkDuplicateName(e.target.value)
                                            })} mb="0px" id="name" placeholder="eg. Cola"
                                                        label="Product Name" error={nameError}/>
                                            {errors.name && <Text color="red.500">{errors.name.message}</Text>}
                                            <InputField {...register("customerName")} defaultValue={customerName} mb="0px" id="customerName"
                                                        placeholder="" label="Customer Name"/>
                                            <InputField {...register("kitchenName")} defaultValue={kitchenName} mb="0px" id="kitchenName"
                                                        placeholder="" label="Kitchen Name"/>
                                        </Stack>
                                        <Stack direction="column" gap="20px">

                                            <Stack direction="column">
                                                <FormLabel
                                                    display='flex'
                                                    fontSize='sm'
                                                    fontWeight='bold'
                                                    _hover={{cursor: 'pointer'}}
                                                    mb='0px'>
                                                    Product Category
                                                </FormLabel>
                                                <Controller
                                                    control={control}
                                                    name="category"
                                                    rules={{ required: 'Product category is required' }}
                                                    render={({field}) =>
                                                        <Select id="category" {...field} value={selectedCategory}>
                                                            <option value="">Select a Category...</option>
                                                            {//@ts-ignore
                                                                 categories && categories.map(category => (
                                                                <option value={category._id}
                                                                        key={category._id}>{category.name}</option>
                                                            ))}
                                                        </Select>
                                                    }
                                                />
                                                {errors.category && <Text color="red.500">{errors.category.message}</Text>}
                                                <AddCategoryModal refetch={handleCategoryClose} title={"Add new category"} onOpen={onCategoryOpen} onClose={onCategoryClose} isOpen={isCategoryOpen}/>
                                            </Stack>

                                            <FormLabel
                                                display='flex'
                                                fontSize='sm'
                                                fontWeight='bold'
                                                _hover={{cursor: 'pointer'}}
                                                mb='0px'>
                                                Button Colour
                                            </FormLabel>
                                            <Controller
                                                control={control}
                                                name="btnColour"
                                                rules={{ required: 'Product colour is required' }}
                                                render={({field}) => (
                                                    <CustomCirclePicker
                                                        {...field}
                                                        defaultColor={field.value}
                                                        onChangeComplete={(color) => {
                                                            field.onChange(color);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </SimpleGrid>
                                    <Flex justify="space-between" mt="24px">
                                        <Button
                                            variant="darkBrand"
                                            fontSize="sm"
                                            borderRadius="16px"
                                            w={{base: '128px', md: '148px'}}
                                            h="46px"
                                            ms="auto"
                                            onClick={() => mediaTab.current.click()}
                                            isDisabled={!isValid}
                                        >
                                            Next
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        </TabPanel>
                        <TabPanel
                            w={{sm: '330px', md: '700px', lg: '850px'}}
                            p="0px"
                            mx="auto"
                        >
                            <Card p="30px">
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Advanced Options
                                </Text>
                                <Text pb={10}>You can assign portions or a modifier group to this product</Text>
                                <Stack direction={['column', 'row']} spacing="24px">
                                    <Box flex="1">
                                        <FormLabel>Portion Group</FormLabel>
                                        <Controller
                                            control={control}
                                            name="variantGroup"
                                            render={({field}) => (
                                                <Select
                                                    {...field}
                                                    onChange={event => {
                                                        field.onChange(event);
                                                        setSelectedPortion(event.target.value);
                                                    }}
                                                    isDisabled={!!watchModifierGroup}
                                                >
                                                    <option>None</option>
                                                    {variantGroups && variantGroups.map((vg: any) => (
                                                        <option key={vg._id} value={vg._id}>
                                                            {vg.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        <Button colorScheme='green' rightIcon={<AddIcon/>} mt={5} onClick={() => onPortionOpen()}>Add new portion group</Button>
                                        <VariantGroupModal isOpen={isPortionOpen} onClose={onPortionClose} refetch={refetchVariants} data={null}/>
                                        <Text fontSize='sm' mt={5}>Portions let you vary the price of a product based on a dimension, e.g. small, medium and large drinks. Portion groups can be shared between multiple products.</Text>
                                    </Box>
                                    <Box flex="1">
                                        <FormLabel>Modifier Group</FormLabel>
                                        <Controller
                                            control={control}
                                            name="modifierGroup"
                                            render={({field}) => (
                                                <Select
                                                    {...field}
                                                    isDisabled={!!watchVariantGroup}
                                                >
                                                    <option>None</option>
                                                    {modifierGroups && modifierGroups.map((mg: any) => (
                                                        <option key={mg._id} value={mg._id}>
                                                            {mg.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        <Text fontSize='sm' mt={5}>Modifier groups let users choose add-ons for products, as well as add instructions e.g. rare for a steak. Modifier groups can be shared between products</Text>
                                    </Box>
                                </Stack>

                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        variant="light"
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{base: '128px', md: '148px'}}
                                        h="46px"
                                        onClick={() => productTab.current.click()}
                                    >
                                        Prev
                                    </Button>
                                    <Button
                                        variant="darkBrand"
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{base: '128px', md: '148px'}}
                                        h="46px"
                                        onClick={() => pricingTab.current.click()}
                                    >
                                        Next
                                    </Button>
                                </Flex>
                            </Card>
                        </TabPanel>
                        <TabPanel
                            w={{sm: '330px', md: '850px', lg: '850px'}}
                            p="0px"
                            mx="auto"
                        >
                            <Card p="30px">
                                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                    Pricing
                                </Text>
                                <Flex direction="column" w="100%">
                                    <Table variant='simple' colorScheme='blue' w={'100%'}>
                                        <Thead>
                                            <Tr>
                                                {watchVariantGroup &&
                                                    <Th>Portion / Order Mode</Th>
                                                }
                                                {orderModes?.map((mode: {
                                                    id: React.Key;
                                                    name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
                                                }) => (
                                                    <Th key={mode.id}>{mode.name}</Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {watchVariantGroup
                                                ? (
                                                    <>
                                                        <Tr>  {/* New tax rate row */}
                                                            <Td>&nbsp;</Td> {/* Empty cell at the beginning of the row */}
                                                            {orderModes?.map((mode: {
                                                                name: any;
                                                                id: React.Key;
                                                                _id: string;
                                                            }) => (
                                                                <Td key={mode.id}>
                                                                    <Select
                                                                        placeholder="Select tax rate"
                                                                        value={defaultTaxRate}
                                                                        {...register(`pricingData.${mode._id}.taxRate`)}
                                                                        width="170px">
                                                                        <option key={'nt'} value={'no-tax'}>
                                                                            No Tax
                                                                        </option>
                                                                        {taxRates && taxRates.map((taxRate: {
                                                                            _id: readonly string[] | React.Key;
                                                                            name: any;
                                                                            rate: number;
                                                                        }) => (
                                                                            <option key={taxRate.name}
                                                                                    value={taxRate._id}>
                                                                                {`${taxRate.name} - ${(taxRate.rate / 100)}%`}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </Td>
                                                            ))}
                                                        </Tr>
                                                        {variantData.map((variant, index) => (
                                                            <Tr key={index}>
                                                                <Td>{variant.name}</Td>
                                                                {orderModes?.map((mode: {
                                                                    name: any;
                                                                    id: React.Key;
                                                                    _id: string;
                                                                }) => {
                                                                    const key = `${variant.name}-${mode.name}`;

                                                                    return (
                                                                        <Td key={mode.id}>

                                                                                <CurrencyInputFieldWithProps
                                                                                    control={control}
                                                                                    placeholder="Price"
                                                                                    {...register(`pricingData.${mode._id}.${variant._id}.price`)}
                                                                                    onChange={(e: { target: { value: any; }; }) => setPricingData({
                                                                                        ...pricingData,
                                                                                        [key]: {
                                                                                            ...pricingData[key],
                                                                                            price: e.target.value
                                                                                        }
                                                                                    })}
                                                                                    width="100px"
                                                                                    mr={2}/>

                                                                        </Td>
                                                                    );
                                                                })}
                                                            </Tr>
                                                        ))}
                                                    </>
                                                )
                                                : (
                                                    <Tr>
                                                        {orderModes?.map((mode: { id: React.Key; _id: string; }) => {
                                                            const key = `${mode._id}`;
                                                            return (
                                                                <Td key={mode.id}>

                                                                    <Box width="300px">
                                                                        <Stack direction="row" spacing={1}>
                                                                            <CurrencyInputFieldWithProps
                                                                                control={control}
                                                                                placeholder="Price"
                                                                                {...register(`pricingData.${key}.price`)}
                                                                                onChange={(e: { target: { value: any; }; }) => setPricingData({
                                                                                    ...pricingData,
                                                                                    [key]: {
                                                                                        ...pricingData[key],
                                                                                        price: e.target.value
                                                                                    }
                                                                                })}
                                                                                width="120px"
                                                                            />
                                                                            <Select
                                                                                placeholder="Select tax rate 1"
                                                                                value={defaultTaxRate}
                                                                                {...register(`pricingData.${key}.taxRate`)}
                                                                                onChange={(e) => setPricingData({
                                                                                    ...pricingData,
                                                                                    [key]: {
                                                                                        ...pricingData[key],
                                                                                        taxRate: e.target.value
                                                                                    }
                                                                                })}
                                                                                width="160px"
                                                                            >
                                                                                <option key={'nt'}
                                                                                        value={'no-tax'}>
                                                                                   No Tax
                                                                                </option>
                                                                                {taxRates && taxRates.map((taxRate: {
                                                                                    _id: string;
                                                                                    name: string;
                                                                                    rate: number
                                                                                }) => (
                                                                                    <option key={taxRate._id}
                                                                                            selected
                                                                                            value={taxRate._id}>
                                                                                        {`${taxRate.name} - ${(taxRate.rate / 100)}%`}
                                                                                    </option>
                                                                                ))}
                                                                            </Select>
                                                                        </Stack>
                                                                    </Box>

                                                                </Td>
                                                            );
                                                        })}
                                                    </Tr>
                                                )
                                            }
                                        </Tbody>
                                    </Table>

                                </Flex>
                                <Button
                                    variant="darkBrand"
                                    fontSize="sm"
                                    borderRadius="16px"
                                    h="46px"
                                    mt="2rem"
                                    type="submit"
                                    isLoading={createLoading}
                                    loadingText='Creating Product'
                                    isDisabled={created}
                                >
                                    Save
                                </Button>
                            </Card>
                        </TabPanel>
                    </TabPanels>
                </form>
            </Tabs>


            <AlertDialog isOpen={isOpen} onClose={onClose}  leastDestructiveRef={cancelRef}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Product created successfully!</AlertDialogHeader>
                        <AlertDialogBody>
                            Would you like to add another product or view all products?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={goToAddNewProduct} colorScheme="green" ml={3}>
                                Add New Product
                            </Button>
                            <Button onClick={goToAllProducts} colorScheme="blue" ml={3} ref={cancelRef}>
                                View All Products
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Flex>
    );
}
