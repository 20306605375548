import {ApolloClient, ApolloError, ApolloLink, gql, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {useContext, useEffect} from "react";
import {useApp} from "@realm/react";
import {UserContext} from "../../contexts/UserContext"

const GET_PRODUCTS = gql`
    query Products($query: ProductQueryInput) {
        products(query: $query, limit: 200) {
            _id
            name
            price
            btnColour
            deletedOn
            category {
                name
            }
            variantGroups{
                _id
            }
            priceDetails {
                orderMode
                taxRate 
                price
                modifierStep
            }
        }
    }
`;

const useGetProducts = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const shouldSkip = !user?.accessToken;


    useEffect(() => {
        console.log("user change");
        if(user){
            refetch();
        }
    }, [user]);

    const { data, loading, error, refetch } = useQuery(GET_PRODUCTS,
        {
            variables: {
                query: {
                    owner_id: app.currentUser?.id,
                }
            },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            errorPolicy: "all",
            skip: shouldSkip,
        }
    );

    if (error) {
        const networkError = error.networkError;
        console.log("Network ERRor")
        console.log(networkError)
        if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
            user.refreshCustomData().then(() => {
                // Once token is refreshed, refetch the query.
                refetch();
            });
        }
    }

    if (error instanceof ApolloError) {
        console.log("APOLLO ERROR")
        console.log(error)
        console.log(error.message)
        const isTokenExpiredError = error.graphQLErrors.some(
            (graphQLError) => graphQLError.message === 'invalid session: access token expired');

        if (isTokenExpiredError) {
            console.log("token exp");
            user.refreshCustomData().then(() => {
                console.log("refetch");
                refetch();
            });
        }
    }

    const products = data?.products;

  //  const sortedProducts = products?.sort((a: { deletedOn: any; }, b: { deletedOn: any; }) => (Number(a.deletedOn != null) - Number(b.deletedOn != null)));

    return [{
        data: products,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetProducts;
